import { DateUtils } from "react-day-picker";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import css from "./DateSelector.module.css";

import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";

const parseDate = (str, format, locale) => {
  const parsed = dateFnsParse(str, format, new Date(), { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
};

const formatDate = (date, format, locale) => {
  return dateFnsFormat(date, format, { locale });
};

const dispDate = (date) => {
  if (date) {
    if (date.toDateString() === new Date().toDateString()) {
      return "Today";
    }
    return date;
  }
};

const DateSelector = (props) => {
  const FORMAT = "dd/MM/yyyy";
  return (
    <div className={css.dayPicker}>
      <span>Week Ending</span>
      <DayPickerInput
        formatDate={formatDate}
        format={FORMAT}
        parseDate={parseDate}
        placeholder="Today"
        onDayChange={props.change}
        value={dispDate(props.date)}
        dayPickerProps={{
          disabledDays: { after: new Date() },
          todayButton: "Today",
        }}
      ></DayPickerInput>
    </div>
  );
};

export default DateSelector;
