export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const GET_BALANCE_SUCCESS = "GET_BALANCE_SUCCESS";
export const GET_ACCOUNTS_SUCCESS = "GET_ACCOUNTS_SUCCESS";
export const GET_NAME_SUCCESS = "GET_NAME_SUCCESS";
export const TOGGLE_ROUNDUP = "TOGGLE_ROUNDUP";
export const UPDATE_ROUNDUP = "UPDATE_ROUNDUP";
export const UPDATE_MULTIPLIER = "UPDATE_MULTIPLIER";
export const UPDATE_PERCENTAGE = "UPDATE_PERCENTAGE";
export const UPDATE_ROUNDUP_TYPE = "UPDATE_ROUNDUP_TYPE";
export const TRANSFER_COMPLETE = "TRANSFER_COMPLETE";
export const TRANSFER_START = "TRANSFER_START";
export const UPDATE_SAVINGS_GOAL_LIST = "UPDATE_SAVINGS_GOAL_LIST";
export const UPDATE_SAVINGS_GOAL = "UPDATE_SAVINGS_GOAL";
export const UPDATE_DATE = "UPDATE_DATE";
export const UPDATE_TOTAL_TRANSFER = "UPDATE_TOTAL_TRANSFER";
