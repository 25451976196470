import calculateRoundUp from "../../../../../utils/calculateRoundUp";

const displayTime = (transactionTime) => {
  const tstamp = new Date(transactionTime);
  return tstamp.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
};

const monogramText = (counterPartyName) => {
  return counterPartyName
    .split(" ")
    .slice(0, 2)
    .map((n) => n[0])
    .join("");
};

const displayLogo = (css, type, counterPartyName) => {
  if (type === "SAVING") {
    return (
      <div className={css.transactionLogoSvg}>
        <svg width="50" height="50" viewBox="0 0 42 42">
          <path fill="#FFF" d="M0 0h42v42H0z"></path>
          <path
            fill="#50E3C2"
            d="M9 7h9a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2z"
          ></path>
          <path
            fill="#A7F1E0"
            d="M24 7h9a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2zM9 22h9a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2v-9a2 2 0 0 1 2-2zM24 22h9a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2v-9a2 2 0 0 1 2-2z"
          ></path>
        </svg>
      </div>
    );
  }
  return (
    <div className={css.transactionMonogram}>
      <p className={css.transactionMonogramText}>
        {monogramText(counterPartyName)}
      </p>
    </div>
  );
};

const displayRoundUp = (css, props, transaction) => {
  if (props.roundUp) {
    return (
      <span className={css.roundUpAmount}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="42"
          height="54"
          viewBox="0 0 42 54"
          className={css.roundUpIcon}
        >
          <path
            d="M42 20.8c0 2.2-.3 4.2-.9 6.2.6 2 .9 4.1.9 6.2C42 44.7 32.6 54 21 54S0 44.7 0 33.2c0-2.2.3-4.3.9-6.2-.6-2-.9-4.1-.9-6.2C0 9.4 9.4 0 21 0s21 9.4 21 20.8zM21 49.7c9.1 0 16.4-7.2 16.6-16.2-3.9 4.9-9.9 8.1-16.6 8.1-6.5 0-12.6-3-16.6-8.1.1 9 7.5 16.2 16.6 16.2zM4.4 20.5v.3c0 1.1.1 2.1.3 3.1.2 1.1.5 2.2.9 3.1 2.6 6.2 8.7 10.3 15.4 10.3 6.8 0 12.8-4.1 15.4-10.3.4-1 .7-2 .9-3.1.2-1 .3-2.1.3-3.1v-.3c-.2-9-7.5-16.2-16.6-16.2-9.1 0-16.5 7.3-16.6 16.2zm26.4 1.4c-.4.4-.9.6-1.5.6s-1.2-.2-1.6-.7l-4.6-4.7v11.6c0 1.2-1 2.2-2.2 2.2-1.2 0-2.2-1-2.2-2.2V17.1l-4.6 4.7c-.8.9-2.2.9-3.1.1-.9-.8-.9-2.2-.1-3.1l8.3-8.6c.8-.8 2.3-.8 3.2 0l8.3 8.6c1 .9 1 2.3.1 3.1"
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
          ></path>
        </svg>
        £{calculateRoundUp(props, transaction).toFixed(2)}
      </span>
    );
  }
};

const Transactions = (props) => {
  const css = props.css;
  return props.dayTransactions.map((transaction) => {
    return (
      <li className={css.listItem} key={transaction.feedItemUid}>
        <div className={css.listItemRow}>
          <div className={css.transactionLogo}>
            {displayLogo(css, props.type, transaction.counterPartyName)}
          </div>
          <div className={css.partyDetails}>
            <span className={css.partyName}>
              {transaction.counterPartyName}
            </span>
            <span className={css.transactionDetails}>
              <span className={css.transactionDetail}>
                {displayTime(transaction.transactionTime)}
              </span>
              <span className={css.transactionDetail}>
                {transaction.spendingCategory.toLowerCase()}
              </span>
            </span>
          </div>
          <div className={css.transactionAndRoundUp}>
            <span className={css.transactionAmount}>
              £{(transaction.amount.minorUnits / 100).toFixed(2)}
            </span>
            {displayRoundUp(css, props, transaction)}
          </div>
        </div>
      </li>
    );
  });
};

export default Transactions;
