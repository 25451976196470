const RoundUpButton = (props) => {
  const css = props.css;
  return (
    <div className={css.roundUpMultiplier}>
      <input
        type="radio"
        id={props.name + props.detail.value}
        name={props.name}
        className={css.roundUpMultiplierXInput}
        value={props.detail.value}
        onChange={props.change}
      />
      <label
        className={css.roundUpMultiplierX}
        htmlFor={props.name + props.detail.value}
        disabled={!props.roundUp}
        {...(props.detail.checked ? { "data-checked": "" } : {})}
      >
        {props.detail.value + props.detail.label}
      </label>
    </div>
  );
};

export default RoundUpButton;
