import round from "./round";

const calculateRoundUp = (props, transaction) => {
  let amount = 0;
  let mamount =
    Math.ceil(transaction.amount.minorUnits / 100) -
    transaction.amount.minorUnits / 100;
  if (!props.roundUpMulti) {
    amount += Math.floor(
      ((transaction.amount.minorUnits / 100) * parseInt(props.percentage)) / 100
    );
  } else {
    mamount *= parseInt(props.multiplier);
  }

  return round(amount + mamount);
};

export default calculateRoundUp;
