import { NavLink } from "react-router-dom";
import css from "./Menu.module.css";

const Menu = (props) => {
  return (
    <nav className={css.menu}>
      <ul className={css.menuList}>
        <li className={css.menuItem}>
          <NavLink
            exact
            className={css.menuLink}
            activeClassName={css.menuLink_active}
            to="/"
          >
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              xmlns="http://www.w3.org/2000/svg"
              className={css.menuIcon}
            >
              <path
                d="M3.128 4.537l1.476 1.476A10.84 10.84 0 002.064 13c0 6.022 4.896 10.903 10.936 10.903 2.625 0 5.035-.922 6.92-2.459l1.476 1.477A12.991 12.991 0 0113 25.98C5.81 25.98-.02 20.168-.02 13c0-3.233 1.186-6.19 3.148-8.463zm1.478-1.46A12.991 12.991 0 0113 .02c3.208 0 6.145 1.156 8.414 3.074l-1.476 1.477A10.912 10.912 0 0013 2.097c-2.624 0-5.032.921-6.917 2.457L4.606 3.077zm18.268 18.384l-1.476-1.476A10.84 10.84 0 0023.937 13c0-2.65-.948-5.078-2.524-6.967l1.476-1.476A12.906 12.906 0 0126.02 13a12.9 12.9 0 01-3.145 8.46zM13 21.82c-4.864 0-8.807-3.95-8.807-8.82C4.192 8.128 8.135 4.18 13 4.18c4.864 0 8.807 3.949 8.807 8.82S17.864 21.82 13 21.82z"
                fill="currentColor"
                fillRule="evenodd"
              ></path>
            </svg>{" "}
            Home
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Menu;
