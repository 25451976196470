const TransferCompleted = (props) => {
  const css = props.css;
  return (
    <div className={css.roundUpWidget}>
      <svg
        className={css.checkmark}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
      >
        <circle
          className={css.checkmark__circle}
          cx="26"
          cy="26"
          r="25"
          fill="none"
        />
        <path
          className={css.checkmark__check}
          fill="none"
          d="M14.1 27.2l7.1 7.2 16.7-16.8"
        />
      </svg>
      <p>£{props.roundUpAmount.toFixed(2)} Transfered Successfully</p>
    </div>
  );
};

export default TransferCompleted;
