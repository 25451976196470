import * as actionTypes from "../actionTypes";

const initialState = {
  accountUid: null,
  defaultCategory: null,
  availableBalance: 0,
  accountHolderName: null,
  transactions: null,
  roundUp: true,
  roundUpAmount: 0.0,
  multiplier: "1",
  percentage: "5",
  roundUpMulti: true,
  transfer: false,
  savingsGoalList: null,
  savingsGoal: null,
  date: null,
  totalTransfer: 0,
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: action.transactions,
        date: action.date,
      };
    case actionTypes.GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accountUid: action.accountUid,
        defaultCategory: action.defaultCategory,
      };
    case actionTypes.GET_NAME_SUCCESS:
      return {
        ...state,
        accountHolderName: action.accountHolderName,
      };
    case actionTypes.GET_BALANCE_SUCCESS:
      return {
        ...state,
        availableBalance: action.availableBalance,
      };
    case actionTypes.TOGGLE_ROUNDUP:
      return {
        ...state,
        roundUp: !state.roundUp,
      };
    case actionTypes.UPDATE_ROUNDUP:
      return {
        ...state,
        roundUpAmount: action.roundUpAmount,
      };
    case actionTypes.UPDATE_MULTIPLIER:
      return {
        ...state,
        multiplier: action.multiplier,
      };
    case actionTypes.UPDATE_PERCENTAGE:
      return {
        ...state,
        percentage: action.percentage,
      };
    case actionTypes.UPDATE_ROUNDUP_TYPE:
      return {
        ...state,
        roundUpMulti: action.roundUpMulti,
      };
    case actionTypes.TRANSFER_COMPLETE:
      return {
        ...state,
        transfer: true,
      };
    case actionTypes.UPDATE_SAVINGS_GOAL_LIST:
      return {
        ...state,
        savingsGoalList: action.savingsGoalList,
      };
    case actionTypes.UPDATE_SAVINGS_GOAL:
      return {
        ...state,
        savingsGoal: action.savingsGoal,
      };
    case actionTypes.UPDATE_DATE:
      return {
        ...state,
        date: action.date,
      };
    case actionTypes.UPDATE_TOTAL_TRANSFER:
      return {
        ...state,
        totalTransfer: action.totalTransfer,
      };
    default:
      return state;
  }
};

export default homeReducer;
