import React, { Component } from "react";
import { connect } from "react-redux";
import css from "./App.module.css";

import * as actions from "./store/actions/home";

import SiteHeader from "./components/SiteHeader/SiteHeader";
import SiteLayout from "./components/SiteLayout/SiteLayout";

class App extends Component {
  state = {
    transferStart: false,
  };
  componentDidMount() {
    if (!this.props.accountUid) {
      this.props.getAccounts();
    }
  }

  handleTransferStart = () => {
    this.setState({ transferStart: true });
    this.props.completeTransfer(this.props.roundUpAmount);
    this.props.toggleRoundUp();
  };

  dateChangeHandler = (event) => {
    this.props.getTransactions(
      this.props.accountUid,
      this.props.defaultCategory,
      event
    );
  };

  renderLoading = () => {
    return (
      <div className={css.Loading}>
        <div className={css.dotPulseN}></div>
      </div>
    );
  };

  renderPage = () => {
    return (
      <div className={css.Layout}>
        <SiteHeader />
        <SiteLayout
          accountHolderName={this.props.accountHolderName}
          availableBalance={this.props.availableBalance}
          transactions={this.props.transactions}
          dateChangeHandler={this.dateChangeHandler}
          date={this.props.date}
          roundUpToggle={this.props.toggleRoundUp}
          roundUp={this.props.roundUp}
          roundUpAmount={this.props.roundUpAmount}
          updateRoundUpAmount={this.props.updateRoundUpAmount}
          multiplier={this.props.multiplier}
          percentage={this.props.percentage}
          updateMultiplier={this.props.updateMultiplier}
          updatePercentage={this.props.updatePercentage}
          roundUpMulti={this.props.roundUpMulti}
          updateRoundUpType={this.props.updateRoundUpType}
          transfer={this.props.transfer}
          completeTransfer={this.props.completeTransfer}
          handleTransferStart={this.handleTransferStart}
          transferStart={this.state.transferStart}
          totalTransfer={this.props.totalTransfer}
          updateTotalTransfer={this.props.updateTotalTransfer}
        />
      </div>
    );
  };

  conditionalRendering = () => {
    if (!this.props.transactions) {
      return this.renderLoading();
    } else {
      return this.renderPage();
    }
  };

  render() {
    return this.conditionalRendering();
  }
}

const mapStateToProps = (state) => {
  return {
    accountUid: state.accountUid,
    defaultCategory: state.defaultCategory,
    availableBalance: state.availableBalance,
    accountHolderName: state.accountHolderName,
    transactions: state.transactions,
    roundUp: state.roundUp,
    roundUpAmount: state.roundUpAmount,
    multiplier: state.multiplier,
    percentage: state.percentage,
    roundUpMulti: state.roundUpMulti,
    transfer: state.transfer,
    date: state.date,
    totalTransfer: state.totalTransfer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAccounts: () => dispatch(actions.getAccounts()),
    getAccountBalance: (accountUid) =>
      dispatch(actions.getAccountBalance(accountUid)),
    getTransactions: (accountUid, defaultCategory, date) =>
      dispatch(actions.getTransactions(accountUid, defaultCategory, date)),
    toggleRoundUp: () => dispatch(actions.toggleRoundUp()),
    updateRoundUpAmount: (roundUpAmount) =>
      dispatch(actions.updateRoundUpAmount(roundUpAmount)),
    updateMultiplier: (event) => dispatch(actions.updateMultiplier(event)),
    updatePercentage: (event) => dispatch(actions.updatePercentage(event)),
    updateRoundUpType: (event) => dispatch(actions.updateRoundUpType(event)),
    completeTransfer: (transferAmount) =>
      dispatch(actions.completeTransfer(transferAmount)),
    updateTotalTransfer: (totalTransfer) =>
      dispatch(actions.updateTotalTransfer(totalTransfer)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
