import RoundUpButton from "./RoundUpButton";

const RoundUpSelector = (props) => {
  const css = props.css;
  return (
    <div className={css.roundUpSelector} disabled={!props.roundUp}>
      {props.data.details.map((detail) => {
        return (
          <RoundUpButton
            key={props.data.name + detail.value}
            css={css}
            detail={detail}
            name={props.data.name}
            change={props.data.change}
            roundUp={props.roundUp}
          ></RoundUpButton>
        );
      })}
    </div>
  );
};

export default RoundUpSelector;
