const RoundUpHeader = (props) => {
  const css = props.css;
  return (
    <header className={css.roundUpHeader}>
      <div className={css.roundUpIconHolder}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.1 2C7.62649 2 4 5.62649 4 10.1C4 10.8183 4.09351 11.5148 4.26901 12.178C4.09104 12.8505 4 13.5472 4 14.2554C4 18.7289 7.62649 22.3554 12.1 22.3554C16.5735 22.3554 20.2 18.7289 20.2 14.2554C20.2 13.5472 20.109 12.8505 19.931 12.178C20.1065 11.5148 20.2 10.8183 20.2 10.1C20.2 5.62649 16.5735 2 12.1 2ZM12.1 3.2C15.9108 3.2 19 6.28924 19 10.1C19 13.9108 15.9108 17 12.1 17C8.28924 17 5.2 13.9108 5.2 10.1C5.2 6.28924 8.28924 3.2 12.1 3.2ZM12.0413 5.3C11.8058 5.3 11.6227 5.39964 11.4221 5.60796L8.50059 8.67861C8.36105 8.8326 8.3 9.00471 8.3 9.23116C8.3 9.64783 8.60524 9.95579 9.01513 9.95579C9.22442 9.95579 9.41628 9.86521 9.54711 9.72029L10.5587 8.62427L11.3436 7.61884L11.2651 9.46666V13.887C11.2651 14.3489 11.5965 14.675 12.0413 14.675C12.4948 14.675 12.8262 14.3489 12.8262 13.887V9.46666L12.7477 7.60977L13.5413 8.63333L14.5442 9.72029C14.675 9.87428 14.8669 9.95579 15.0762 9.95579C15.486 9.95579 15.8 9.64783 15.8 9.23116C15.8 9.00471 15.7302 8.8326 15.5907 8.67861L12.6692 5.60796C12.4861 5.40869 12.2855 5.3 12.0413 5.3ZM18.9841 14.7278L18.995 14.509L18.8934 14.6704C17.4335 16.914 14.908 18.3554 12.1 18.3554C9.23843 18.3554 6.67268 16.8588 5.22699 14.5453L5.206 14.51L5.21592 14.7278C5.45341 18.2402 8.31934 21.0317 11.8628 21.1514L12.1 21.1554C15.752 21.1554 18.7413 18.3182 18.9841 14.7278Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
      <div className={css.roundUpHeading}>
        <label htmlFor="roundUpToggle">Round up your spending</label>
      </div>
      <div className={css.roundUpToggle}>
        <input
          className={css.roundUpSwitchInput}
          type="checkbox"
          id="roundUpToggle"
          value=""
          checked={props.roundUp}
          onChange={props.roundUpToggle}
        />
        <label htmlFor="roundUpToggle">
          <span
            className={css.roundUpSwitchOuter}
            {...(props.roundUp ? { "data-checked": "" } : {})}
            data-focus
          >
            <span
              className={css.roundUpSwitchInner}
              {...(props.roundUp ? { "data-checked": "" } : {})}
            ></span>
          </span>
        </label>
      </div>
    </header>
  );
};

export default RoundUpHeader;
