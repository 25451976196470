import RoundUpHeader from "./RoundUpHeader";
import RoundUpSelector from "./RoundUpSelector";

const empty = () => {
  return true;
};

const dataType = (props) => {
  return {
    name: "roundUpType",
    change: props.updateRoundUpType,
    details: [
      { value: "x", label: "", checked: props.roundUpMulti && props.roundUp },
      { value: "%", label: "", checked: !props.roundUpMulti && props.roundUp },
    ],
  };
};

const dataValueM = (update, multiplier, values) => {
  return {
    name: "roundUpX",
    change: update,
    details: values.map((v) => {
      return { value: v, label: "x", checked: parseInt(multiplier) === v };
    }),
  };
};

const dataValueP = (update, percentage, values) => {
  console.log(percentage);
  return {
    name: "roundUpP",
    change: update,
    details: values.map((v) => {
      return { value: v, label: "%", checked: parseInt(percentage) === v };
    }),
  };
};

const displaySecondary = (props, css) => {
  if (props.roundUp) {
    if (props.roundUpMulti) {
      return (
        <RoundUpSelector
          css={css}
          data={dataValueM(
            props.updateMultiplier,
            props.multiplier,
            [1, 2, 5, 10]
          )}
          roundUp={props.roundUp}
        ></RoundUpSelector>
      );
    } else {
      return (
        <RoundUpSelector
          css={css}
          data={dataValueP(
            props.updatePercentage,
            props.percentage,
            [5, 10, 20, 25]
          )}
          roundUp={props.roundUp}
        ></RoundUpSelector>
      );
    }
  }
};

const transferButtonContent = (css, transferStart) => {
  if (transferStart) {
    return <div className={css.dotPulseN}></div>;
  } else {
    return "Transfer";
  }
};

const infoText = (roundUp, roundUpMulti) => {
  if (!roundUp || roundUpMulti) {
    return "You can choose to round up every debit transaction to the nearest pound and add the change or its multiple to saving goal";
  } else {
    return "You can choose to add a percentage (round down to nearest pound) along with round up amount to nearest pound of every debit transaction to saving goal";
  }
};

const roundUpAmount = (roundUp, roundUpAmountI) => {
  if (roundUp) {
    return roundUpAmountI;
  }
  return 0;
};

const RoundUp = (props) => {
  const css = props.css;
  return (
    <div className={css.roundUpWidget}>
      <RoundUpHeader
        css={css}
        roundUp={props.roundUp}
        roundUpToggle={props.roundUpToggle}
      ></RoundUpHeader>
      <p className={css.roundUpText}>{infoText()}</p>
      <RoundUpSelector
        css={css}
        data={dataType(props)}
        roundUp={props.roundUp}
      ></RoundUpSelector>
      {displaySecondary(props, css)}
      <div className={css.transferAmount}>
        <span className={css.transferAmountText}>Transfer Amount</span>£
        {roundUpAmount(props.roundUp, props.roundUpAmount).toFixed(2)}
      </div>
      <section className={css.transferButtonWrapper}>
        <button
          className={css.transferButton}
          disabled={!props.roundUp}
          onClick={props.transferStart ? empty : props.handleTransferStart}
        >
          {transferButtonContent(css, props.transferStart)}
        </button>
      </section>
    </div>
  );
};

export default RoundUp;
