const AccountBalance = (props) => {
  const css = props.css;
  return (
    <div className={css.accountBalance}>
      <h3 className={css.accountBalanceHeading}>{props.text}</h3>
      <p className={css.accountBalanceAmount}>
        £{(props.availableBalance / 100).toFixed(2)}
      </p>
    </div>
  );
};

export default AccountBalance;
