import {
  NavLink,
  Route,
  Switch,
  BrowserRouter as Router,
} from "react-router-dom";
import Aside from "./Aside/Aside";
import css from "./SiteLayout.module.css";
import DateSelector from "./TransactionFeed/DateSelector/DateSelector";
import TransactionFeed from "./TransactionFeed/TransactionFeed";

const SiteLayout = (props) => {
  return (
    <Router>
      <main className={css.pageWrapper}>
        <section className={css.page}>
          <header className={css.pageHeader}>
            <div className={css.titleWrapper}>
              <h1 className={css.title}>{props.accountHolderName}</h1>
            </div>
            <nav className={css.tabs}>
              <NavLink
                className={css.tab}
                activeClassName={css.tab_active}
                exact
                to="/"
              >
                Round Up Feed
              </NavLink>
              <NavLink
                className={css.tab}
                activeClassName={css.tab_active}
                exact
                to="/savings"
              >
                Savings Feed
              </NavLink>
            </nav>
          </header>

          <Switch>
            <Route exact path="/savings">
              <Aside
                type="SAVING"
                roundUpAmount={props.totalTransfer}
                roundUpToggle={props.roundUpToggle}
              ></Aside>
              <div className={css.pageBody}>
                <TransactionFeed
                  transactions={props.transactions}
                  totalTransfer={props.totalTransfer}
                  updateTotalTransfer={props.updateTotalTransfer}
                  roundUp={false}
                  type="SAVING"
                ></TransactionFeed>
              </div>
            </Route>
            <Route exact path="/">
              <Aside
                availableBalance={props.availableBalance}
                roundUpToggle={props.roundUpToggle}
                roundUp={props.roundUp}
                roundUpAmount={props.roundUpAmount}
                multiplier={props.multiplier}
                percentage={props.percentage}
                updateMultiplier={props.updateMultiplier}
                updatePercentage={props.updatePercentage}
                roundUpMulti={props.roundUpMulti}
                updateRoundUpType={props.updateRoundUpType}
                transfer={props.transfer}
                completeTransfer={props.completeTransfer}
                handleTransferStart={props.handleTransferStart}
                transferStart={props.transferStart}
              ></Aside>
              <div className={css.pageBody}>
                <DateSelector
                  change={props.dateChangeHandler}
                  date={props.date}
                ></DateSelector>
                <TransactionFeed
                  transactions={props.transactions}
                  roundUp={props.roundUp}
                  updateRoundUpAmount={props.updateRoundUpAmount}
                  multiplier={props.multiplier}
                  percentage={props.percentage}
                  roundUpMulti={props.roundUpMulti}
                  transfer={props.transfer}
                  transferStart={props.transferStart}
                ></TransactionFeed>
              </div>
            </Route>
          </Switch>
        </section>
      </main>
    </Router>
  );
};

export default SiteLayout;
