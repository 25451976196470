import * as actionTypes from "../actionTypes";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import bearerToken from "../../bearerToken";

const instance = axios.create({
  baseURL: "api/v2",
  headers: {
    Authorization: "Bearer " + bearerToken,
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const getAccountsSuccess = (accountUid, defaultCategory) => {
  return {
    type: actionTypes.GET_ACCOUNTS_SUCCESS,
    accountUid: accountUid,
    defaultCategory: defaultCategory,
  };
};

export const getAccounts = () => {
  return (dispatch) => {
    instance
      .get("/accounts")
      .then((response) => {
        dispatch(
          getAccountsSuccess(
            response.data.accounts[0].accountUid,
            response.data.accounts[0].defaultCategory
          )
        );
        dispatch(getAccountName());
        dispatch(getAccountBalance(response.data.accounts[0].accountUid));
        dispatch(
          getTransactions(
            response.data.accounts[0].accountUid,
            response.data.accounts[0].defaultCategory,
            new Date()
          )
        );
        dispatch(getSavingsGoalList(response.data.accounts[0].accountUid));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getNameSuccess = (accountHolderName) => {
  return {
    type: actionTypes.GET_NAME_SUCCESS,
    accountHolderName: accountHolderName,
  };
};

export const getAccountName = () => {
  return (dispatch) => {
    instance
      .get("/account-holder/name")
      .then((response) => {
        dispatch(getNameSuccess(response.data.accountHolderName));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getBalanceSuccess = (availableBalance) => {
  return {
    type: actionTypes.GET_BALANCE_SUCCESS,
    availableBalance: availableBalance,
  };
};

export const getAccountBalance = (accountUid) => {
  return (dispatch) => {
    instance
      .get("/accounts/" + accountUid + "/balance")
      .then((response) => {
        dispatch(getBalanceSuccess(response.data.amount.minorUnits));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getTransactionsSuccess = (transactions, date) => {
  return {
    type: actionTypes.GET_TRANSACTIONS_SUCCESS,
    transactions: transactions,
    date: date,
  };
};

export const getTransactions = (accountUid, categoryUid, to) => {
  to.setHours(23, 59, 59, 999);
  const since = new Date(to);
  since.setHours(0, 0, 0, 0);
  since.setDate(since.getDate() - 7);
  return (dispatch) => {
    instance
      .get(
        "/feed/account/" +
          accountUid +
          "/category/" +
          categoryUid +
          "/transactions-between?minTransactionTimestamp=" +
          since.toISOString() +
          "&maxTransactionTimestamp=" +
          to.toISOString()
      )
      .then((response) => {
        dispatch(getTransactionsSuccess(response.data.feedItems, to));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const toggleRoundUp = () => {
  return {
    type: actionTypes.TOGGLE_ROUNDUP,
  };
};

export const updateRoundUpAmount = (roundUpAmount) => {
  return {
    type: actionTypes.UPDATE_ROUNDUP,
    roundUpAmount: roundUpAmount,
  };
};

export const updateMultiplier = (event) => {
  return {
    type: actionTypes.UPDATE_MULTIPLIER,
    multiplier: event.target.value,
  };
};

export const updatePercentage = (event) => {
  return {
    type: actionTypes.UPDATE_PERCENTAGE,
    percentage: event.target.value,
  };
};

export const updateRoundUpType = (event) => {
  return {
    type: actionTypes.UPDATE_ROUNDUP_TYPE,
    roundUpMulti: event.target.value === "x",
  };
};

export const executeTransfer = () => {
  return {
    type: actionTypes.TRANSFER_COMPLETE,
  };
};

export const completeTransfer = (transferAmount) => {
  return (dispatch, getState) => {
    instance
      .put(
        "/account/" +
          getState().accountUid +
          "/savings-goals/" +
          getState().savingsGoal.savingsGoalUid +
          "/add-money/" +
          uuidv4(),
        { amount: { currency: "GBP", minorUnits: transferAmount * 100 } }
      )
      .then((response) => {
        dispatch(executeTransfer());
        dispatch(getAccountBalance(getState().accountUid));
        dispatch(
          getTransactions(
            getState().accountUid,
            getState().defaultCategory,
            getState().date
          )
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getSavingsGoalList = (accountUid) => {
  return (dispatch) => {
    instance
      .get("/account/" + accountUid + "/savings-goals")
      .then((response) => {
        if (response.data.savingsGoalList.length === 0) {
          dispatch(createSavingsGoal("Savings Goal"));
        } else {
          dispatch(updateSavingsGoalList(response.data.savingsGoalList));
          dispatch(updateSavingsGoal(response.data.savingsGoalList[0]));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const createSavingsGoal = (name) => {
  return (dispatch, getState) => {
    instance
      .put("/account/" + getState().accountUid + "/savings-goals", {
        name: name,
        currency: "GBP",
      })
      .then((response) => {
        dispatch(getSavingsGoalList(getState().accountUid));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const updateSavingsGoalList = (savingsGoalList) => {
  return {
    type: actionTypes.UPDATE_SAVINGS_GOAL_LIST,
    savingsGoalList: savingsGoalList,
  };
};

export const updateSavingsGoal = (savingsGoal) => {
  return {
    type: actionTypes.UPDATE_SAVINGS_GOAL,
    savingsGoal: savingsGoal,
  };
};

export const updateTotalTransfer = (totalTransfer) => {
  return {
    type: actionTypes.UPDATE_TOTAL_TRANSFER,
    totalTransfer: totalTransfer,
  };
};
