import React, { useEffect } from "react";
import calculateRoundUp from "../../../utils/calculateRoundUp";
import round from "../../../utils/round";
import TransactionDay from "./TransactionDay/TransactionDay";
import css from "./TransactionFeed.module.css";

let totalRoundUp = 0.0;

const updateRoundUp = (number) => {
  totalRoundUp += number;
  return number;
};

const toDisplayDate = (transactionTime) => {
  const tstamp = new Date(transactionTime);
  return tstamp.toLocaleDateString([], {
    weekday: "long",
    day: "numeric",
    month: "long",
  });
};

const divideByDate = (transactions, props) => {
  totalRoundUp = 0.0;
  if (!transactions) {
    return null;
  }
  let previousDate = null;
  let innerArr = [];
  let retArr = [];
  transactions.forEach((transaction) => {
    if (
      transaction.direction === "OUT" &&
      (props.type === "SAVING"
        ? transaction.spendingCategory === "SAVING"
        : transaction.spendingCategory !== "SAVING")
    ) {
      if (toDisplayDate(transaction.transactionTime) !== previousDate) {
        if (previousDate) {
          retArr.push(
            <TransactionDay
              key={previousDate}
              css={css}
              previousDate={previousDate}
              dayTransactions={innerArr}
              {...props}
            ></TransactionDay>
          );
        }
        innerArr = [];
        previousDate = toDisplayDate(transaction.transactionTime);
      }
      updateRoundUp(
        props.type === "SAVING"
          ? transaction.amount.minorUnits
          : calculateRoundUp(props, transaction)
      );
      innerArr.push(transaction);
    }
  });
  if (previousDate) {
    retArr.push(
      <TransactionDay
        key={previousDate}
        css={css}
        previousDate={previousDate}
        dayTransactions={innerArr}
        {...props}
      ></TransactionDay>
    );
  }
  return retArr;
};

const TransactionFeed = (props) => {
  useEffect(() => {
    if (!props.transferStart && props.type !== "SAVING") {
      props.updateRoundUpAmount(round(totalRoundUp));
    }
    if (props.type === "SAVING" && totalRoundUp !== props.totalTransfer) {
      props.updateTotalTransfer(totalRoundUp);
    }
  });
  return <div>{divideByDate(props.transactions, props)}</div>;
};

export default React.memo(TransactionFeed);
