import AccountBalance from "./AccountBalance/AccountBalance";
import css from "./Aside.module.css";
import RoundUp from "./RoundUp/RoundUp";
import TransferCompleted from "./TransferCompleted/TransferCompleted";

const viewTransferWidget = (props) => {
  if (props.type !== "SAVING") {
    if (props.transfer) {
      return (
        <TransferCompleted
          css={css}
          roundUpAmount={props.roundUpAmount}
        ></TransferCompleted>
      );
    } else {
      return <RoundUp css={css} {...props}></RoundUp>;
    }
  }
};

const Aside = (props) => {
  return (
    <aside className={css.pageAside}>
      <section className={css.widget}>
        <AccountBalance
          css={css}
          availableBalance={
            props.type !== "SAVING"
              ? props.availableBalance
              : props.roundUpAmount
          }
          text={props.type !== "SAVING" ? "Your Balance" : "Total Transferred"}
        ></AccountBalance>
      </section>
      <section className={css.widget}>{viewTransferWidget(props)}</section>
    </aside>
  );
};

export default Aside;
