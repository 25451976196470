import { Fragment } from "react";
import Transactions from "./Transactions/Transactions";

const getToday = () => {
  const tstamp = new Date();
  return tstamp.toLocaleDateString([], {
    weekday: "long",
    day: "numeric",
    month: "long",
  });
};

const TransactionDay = (props) => {
  const css = props.css;
  return (
    <Fragment key={props.previousDate}>
      <h4 className={css.listHeading}>
        {props.previousDate === getToday() ? "Today" : props.previousDate}
      </h4>
      <ul className={css.transactionList}>
        <Transactions key={props.previousDate} {...props}></Transactions>
      </ul>
    </Fragment>
  );
};

export default TransactionDay;
